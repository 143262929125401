// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pozlacovani-a-polychromie-js": () => import("./../../../src/pages/pozlacovani-a-polychromie.js" /* webpackChunkName: "component---src-pages-pozlacovani-a-polychromie-js" */),
  "component---src-pages-restaurovani-nabytku-js": () => import("./../../../src/pages/restaurovani-nabytku.js" /* webpackChunkName: "component---src-pages-restaurovani-nabytku-js" */),
  "component---src-pages-rezba-volna-tvorba-js": () => import("./../../../src/pages/rezba-volna-tvorba.js" /* webpackChunkName: "component---src-pages-rezba-volna-tvorba-js" */),
  "component---src-templates-before-after-js": () => import("./../../../src/templates/before-after.js" /* webpackChunkName: "component---src-templates-before-after-js" */),
  "component---src-templates-simple-js": () => import("./../../../src/templates/simple.js" /* webpackChunkName: "component---src-templates-simple-js" */)
}

